import { createTheme, ThemeProvider } from "@mui/material";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { COLORS } from "./static/color";
import { API_CONFIG, APP_PATH, STAGE_NAME } from "./static/common";
import { Amplify } from "aws-amplify";

const Home = React.lazy(() => import("./pages/Home"));
const PrivacyStatement = React.lazy(() => import("./pages/PrivacyStatement"));
const TermCondition = React.lazy(() => import("./pages/TermCondition"));
const Author = React.lazy(() => import("./pages/Author"));

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.text,
    },
  },
  typography: {
    fontFamily: "Poppins",
  },
});

Amplify.configure({
  endpoints: [
    {
      name: "message-gpt",
      endpoint: API_CONFIG[STAGE_NAME].messageGptApi.endpoint,
      region: "us-east-1",
      custom_header: async () => {
        return {
          "X-API-Key": API_CONFIG[STAGE_NAME].messageGptApi.apiKey,
        };
      },
    },
  ],
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path={APP_PATH.HOME}
            element={
              <Suspense fallback={null}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.PRIVACY_STATEMENT}
            element={
              <Suspense fallback={null}>
                <PrivacyStatement />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.TERMS_AND_CONDITIONS}
            element={
              <Suspense fallback={null}>
                <TermCondition />
              </Suspense>
            }
          />
          <Route
            path={APP_PATH.AUTHOR}
            element={
              <Suspense fallback={null}>
                <Author />
              </Suspense>
            }
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
