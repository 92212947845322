export const APP_PATH = {
  HOME: "/",
  PRIVACY_STATEMENT: "/privacy-statement",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  AUTHOR: "/author"
};

export const DOMAIN_STAGE_MAP: { [key: string]: string } = {
  localhost: "dev",
  "alpha.ai2006.io": "alpha",
  "beta.ai2006.io": "beta",
  "ai2006.io": "prod",
  "www.ai2006.io": "prod",
};

export const DOMAIN_NAME = window.location.hostname;
export const STAGE_NAME = DOMAIN_STAGE_MAP[DOMAIN_NAME];

interface IApiConfig {
  endpoint: string;
  apiKey: string;
}

export const API_CONFIG: {
  [key: string]: {
    messageGptApi: IApiConfig;
  };
} = {
  dev: {
    messageGptApi: {
      endpoint: "https://1gx33pk0ui.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "nYEUuQDOZP6Z68Ci4de1a9wrIWmrNrQr2TSe9gcU",
    },
  },
  alpha: {
    messageGptApi: {
      endpoint: "https://1gx33pk0ui.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "nYEUuQDOZP6Z68Ci4de1a9wrIWmrNrQr2TSe9gcU",
    },
  },
  beta: {
    messageGptApi: {
      endpoint: "https://cgnbln3xh5.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "80jkpP4yOE4a1q4CHld4c38t2P29ujnK26YgXf0K",
    },
  },
  prod: {
    messageGptApi: {
      endpoint: "https://6ddvgs99v6.execute-api.us-east-1.amazonaws.com/prod",
      apiKey: "utDn55FYH78uRzZ9b87oD2aBe9y3hjOsa9gkV9KU",
    },
  },
};

export const EVENT_TYPE = {
  SAMPLE_EVENT: 'SAMPLE_EVENT',
};
